class ConfigService {
    constructor() {
      this.config = null;
    }
  
    async loadConfig() {
      if (!this.config) {
        const response = await fetch('/config.json');  // fetch from public/
        this.config = await response.json();
      }
      return this.config;
    }
  
    getConfig() {
      if (!this.config) {
        throw new Error("Config not loaded yet. Call loadConfig() first.");
      }
      return this.config;
    }
  }
  
  const configService = new ConfigService();
  export default configService;
  