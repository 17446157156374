// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// Notice we do NOT import App directly here:
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import configService from './ConfigService'; // <-- Our service to fetch config.json

async function startApp() {
  try {
    // 1) Load config from public/config.json
    await configService.loadConfig();

    // 2) Retrieve the AWS config (and anything else) from the loaded config
    const { awsConfig } = configService.getConfig();

    // 3) Configure Amplify using the config from config.json
    Amplify.configure(awsConfig);

    // 4) Dynamically import App AFTER config is loaded
    //    (so any usage of config in App won't fail)
    const { default: App } = await import('./App');

    // 5) Now render your app
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );

    // 6) Optional: start measuring performance
    reportWebVitals();
  } catch (error) {
    console.error('Failed to load or configure with config.json:', error);
  }
}

startApp();
